import PropTypes, { func } from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Table,
  InputGroup,
  Label,
} from "reactstrap";

import { Portuguese } from "flatpickr/dist/l10n/pt"
import { api } from "../../config"

//i18n
import { withTranslation } from "react-i18next";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";

const Dashboard = props => {

  function currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, 'R$1,')
  };

  const [secao, setSecao] = useState('')
  const [vendasFiltradas, setVendasFiltradas] = useState([])
  const [dataSelect, setDataSelect] = useState('')
  const [secaoN, setSecaoN] = useState(false)

  async function handleDados(){
    setSecaoN(JSON.parse(localStorage.getItem('user')).secao.length > 1 ? true : false)
    setDataSelect(moment(new Date()).format('YYYY-MM-DD'))
    setSecao(JSON.parse(localStorage.getItem('user')).secao[0].id)

    await fetch(`${api.URL_LOCAL}vendas/${moment(new Date()).format('YYYY-MM-DD')}/${JSON.parse(localStorage.getItem('user')).secao[0].id}`)
    .then(pd => pd.json())
    .then(ret => {
      setVendasFiltradas(ret)
      if(ret.length > 0){
        var totalV = ret.reduce(function(accumulator,object){ 
          return accumulator + object.total_venda
        },0); 
        var totalN = ret.reduce(function(accumulator,object){ 
          return accumulator + object.num_venda
        },0); 
        var totalP = ret.reduce(function(accumulator,object){ 
          return accumulator + object.percentual
        },0); 
        setTotalVendas(totalV)
        setTotalProc(totalP)
        setTotalNum(totalN)
      }
    }).catch(err => console.log(err))
  }

  const [totalVendas, setTotalVendas] = useState(0)
  const [totalNum, setTotalNum] = useState(0)
  const [totalPorc, setTotalProc] = useState(0)

  async function getVendasData(){
    //console.log(`${api.URL_LOCAL}vendas/${dataSelect}/${secao}`)
    await fetch(`${api.URL_LOCAL}vendas/${dataSelect}/${secao}`)
    .then(pd => pd.json())
    .then(ret => {
      setVendasFiltradas(ret)
      if(ret.length > 0){
        var totalV = ret.reduce(function(accumulator,object){ 
          return accumulator + object.total_venda
        },0); 
        var totalN = ret.reduce(function(accumulator,object){ 
          return accumulator + object.num_venda
        },0); 
        var totalP = ret.reduce(function(accumulator,object){ 
          return accumulator + object.percentual
        },0); 
        setTotalVendas(totalV)
        setTotalProc(totalP)
        setTotalNum(totalN)
      }
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    handleDados()
  },[])

  //meta title
  document.title = "Dashboard | ProLoja";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h5">Relatório de vendas </CardTitle>
                  <CardSubtitle className="card-title-desc">
                  </CardSubtitle>
                  <form>
                  <Row className="mb-4">
                    <Col lg={secaoN ? 5 : 10}>
                      <div className="mb-3">
                        <Label>Data</Label>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Selecione a data"
                          value={dataSelect}
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d",
                            locale: Portuguese
                          }}
                          onChange={(t) => setDataSelect(moment(t[0]).format('YYYY-MM-DD'))}
                        />
                      </div>
                    </Col>
                    {
                      secaoN
                      &&
                      <Col lg={5}>
                        <div className="mb-3">
                          <Label>Seção</Label>
                          <select className="form-control" value={secao} onChange={(t) => setSecao(t.target.value)}>
                            <option></option>
                            {
                              (JSON.parse(localStorage.getItem('user')).secao || []).map((a,b) => (
                                <option key={b} value={a.id}>{a.descricao}</option>
                              ))
                            }
                            
                          </select>
                        </div>
                      </Col>
                    }
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label>&nbsp;</Label>
                        <Button color="primary" onClick={() => getVendasData()} block outline>
                          Pesquisar
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  </form>
                  
                  {
                    vendasFiltradas.length > 0
                    ?
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Filial</th>
                              <th style={{width: 120, textAlign: 'end'}}>Nº de Vendas</th>
                              <th style={{width: 120, textAlign: 'end'}}>Total R$</th>
                              <th style={{width: 120, textAlign: 'end'}}>%</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            (vendasFiltradas || []).map((a,b) => (
                              <tr key={b}>
                                <th scope="row" >{b+1 >= 10 ? b+1 : '0'+(b+1)}</th>
                                <th scope="row" >{a.filial}</th>
                                <td style={{textAlign: 'end'}}>{a.num_venda < 10 ? '0'+a.num_venda : a.num_venda}</td>
                                <td style={{textAlign: 'end'}}>{a.total_venda.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                <td style={{textAlign: 'end'}}>{a.percentual}</td>
                              </tr>
                            ))
                          }
                          </tbody>
                          <tbody>
                            <tr>
                            <th scope="row" > </th>
                            <th scope="row" > </th>
                              <td style={{textAlign: 'end'}}><b>{totalNum < 10 ? '0'+totalNum : totalNum}</b></td>
                              <td style={{textAlign: 'end'}}><b>{totalVendas.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></td>
                              <td style={{textAlign: 'end'}}><b>{currencyFormat(parseFloat(totalPorc))}</b></td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    :
                    <p className="text-body-secondary">
                      Nenhum registro encontrado, selecione a data desejada para continuar.
                    </p>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
          
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
