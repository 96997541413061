import React from 'react'

export const ProdutosVendaContext = React.createContext({})

export const ProdutosVendaProvider = (props) => {

  const [produtosVenda, setProdutosVenda] = React.useState([{ id: null }])
  const [producao, setProducao] = React.useState(false)
  const [qtdeItens, setQtdeItens] = React.useState(0)
  const [totalVenda, setTotalVenda] = React.useState(0)
  const [modalFinalizaVenda, setModalFinalizaVenda] = React.useState(false)
  const [modalMesas, setModalMesas] = React.useState(false)
  const [modalPedidos, setModalPedidos] = React.useState(false)

  //VERIFICA O TIPO DE FINALIZAÇÃO QUANDO CLIENTE FOR PAGAR
  const [tipoFinaliza, setTipoFinaliza] = React.useState('VENDA_DIRETA')
  //INFORMA OS DETALHES DO PEDIDO
  const [infoVenda, setInfoVenda] = React.useState({ mesa: null })

  return (
    <ProdutosVendaContext.Provider value={
      {
        produtosVenda, setProdutosVenda,
        modalFinalizaVenda, setModalFinalizaVenda,
        producao, setProducao,
        totalVenda, setTotalVenda,
        qtdeItens, setQtdeItens,
        modalMesas, setModalMesas,
        modalPedidos, setModalPedidos,
        tipoFinaliza, setTipoFinaliza,
        infoVenda, setInfoVenda
      }
    }>
      {props.children}
    </ProdutosVendaContext.Provider>
  )
}