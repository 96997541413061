import PropTypes from "prop-types";
import React, { useState } from "react";

import { 
  Row, 
  Col, 
  CardBody, 
  Card, 
  Alert, 
  Container, 
  Form, 
  Input,
  InputGroup,
  FormFeedback, 
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
 } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";

//import { ToastContainer, toast } from 'react-toast-notifications'

import { AuthContext } from "contexts/providers/Auth"
import { api } from "../../config"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo_teste1.jpeg";

const Login = props => {

  //meta title
  document.title = "Login - ProLoja";

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false)
  const [usuario, setUsuario] = useState('')
  const [valores, setValores] = useState({})
  const [novaSenha, setNovaSenha] = useState('')
  const [senhaShow, setSenhaShow] = useState(false)
  const [novaSenhaShow, setNovaSenhaShow] = useState(false)
  const [novaSenhaShow1, setNovaSenhaShow1] = useState(false)
  const [novaSenha1, setNovaSenha1] = useState('')

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_modal() {
    setModal(!modal);
    removeBodyCss();
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "" || '',
      password: "" || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Informe seu email"),
      password: Yup.string().required("Informe sua senha"),
    }),
    onSubmit: (values) => {
      //console.log(`${api.URL_LOCAL}usuarios/retornaUsuario/${values.email}/${values.password}`)
      fetch(`${api.URL_LOCAL}usuario/${values.email}/${values.password}`)
        .then(pd => pd.json())
        .then(json => {
          //console.log(json.msg)
          if (json.length == 0) {
            alert("Usuário ou senha inválidos.")
          } else {
            let secoes = []
            fetch(`${api.URL_LOCAL}usuario-secao/${json[0].id}`)
            .then(pd => pd.json())
            .then(ret => {
              if(ret.length > 0){
                for (let index = 0; index < ret.length; index++) {
                  secoes = [...secoes, {id: ret[index].secao, descricao: ret[index].descricao}]
                }
              }
              localStorage.setItem('user', JSON.stringify({
                id: json[0].id,
                email: json[0].email,
                secao: secoes
              }))
              if(values.password == '0'){
                setModal(true)
                setUsuario(json[0].id,)
                setValores(values)
              }else{
                dispatch(loginUser(values, props.router.navigate));
              }
            }).catch(err => console.log(err))
          }
        })
      //dispatch(loginUser(values, props.router.navigate));
    }
  });

  const [msgErro, setMsgErro] = useState('')

  async function handleAlteraSenha(){
    if(novaSenha == ''){
      setMsgErro('Preencha a senha')
    } else if(novaSenha.length < 4){
      setMsgErro('Nova senha não pode ter menos de 4 caracteres')
    }else if(novaSenha != novaSenha1){
      setMsgErro('Senhas não conferem')
    }else if(novaSenha1 == ''){
      setMsgErro('Preencha a senha')
    }else{
      await fetch(`${api.URL_LOCAL}usuarioSenha/${usuario}/${novaSenha}`, {
        method: 'PUT',
        body: JSON.stringify({
          senha: novaSenha
        }),
        headers: new Headers({
          'content-type': 'application/json',
          'Accept': 'application/json'
        })
      })
        .then(() => {
          dispatch(loginUser(valores, props.router.navigate));
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  async function getCaixa(e) {

    console.log('verificando')
    await fetch(`${api.URL_LOCAL}lojas/verificaCaixa/${e}/true`)
      .then(pd => pd.json())
      .then(json => {
        if (json.length > 0) {

          console.log(JSON.stringify(json[0]))
          //setCaixa({_id: json[0]._id, aberto: true})

          localStorage.setItem('caixa', JSON.stringify(json[0]))
        } else {
          console.log('passou')
          localStorage.setItem('caixa', JSON.stringify({ _id: null, aberto: false }))

        }
      }).catch(() => {
        null
      })
  }

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  const signIn = type => {
    dispatch(socialLogin(type, props.router.navigate));
  };

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type);
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className=" bg-soft" style={{width: '100%'}}>
                  <img
                    src={logo}
                    alt=""
                    style={{width: 220, height: 85, marginLeft: '25%', marginTop: 10, marginBottom: 15 }}
                  />
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">E-mail</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                          
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="senha"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      
                      {/*
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Não sou um robô
                          </label>
                          </div>
                        */}

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      {/*
                      <div className="mt-4 text-center">
                        <Link to="#" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Esqueceu sua senha?
                        </Link>
                      </div>
                      */}
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} ProLoja.
                </p>
              </div>
            </Col>
            <Modal
              size="sm"
              isOpen={modal}
              toggle={() => {
                tog_modal();
              }}
            >
              <div className="modal-header">
                <h5
                  className="modal-title mt-0"
                  id="mySmallModalLabel"
                >
                  Primeiro acesso
                </h5>
                <button
                  onClick={() => {
                    setModal(false);
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Vamos alterar sua senha.
                </p>
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label className="form-label">Nova senha</Label>
                      <InputGroup>
                        <input type={novaSenhaShow ? 'text' : 'password'} className="form-control" id="autoSizingInputGroup" placeholder="" onChange={(t) => setNovaSenha(t.target.value)} />
                        <div className="input-group-text" onClick={() => setNovaSenhaShow(!novaSenhaShow)} style={{cursor: 'pointer'}}>
                          {
                            novaSenhaShow
                            ? <i className="bx bxs-lock-open"></i>
                            : <i className="bx bxs-lock"></i>
                          }
                        </div>
                        </InputGroup>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label className="form-label">Repita a senha</Label>
                      <InputGroup>
                        <input type={novaSenhaShow1 ? 'text' : 'password'} className="form-control" id="autoSizingInputGroup1" placeholder="" onChange={(t) => setNovaSenha1(t.target.value)} />
                        <div className="input-group-text" onClick={() => setNovaSenhaShow1(!novaSenhaShow1)} style={{cursor: 'pointer'}}>
                          {
                            novaSenhaShow1
                            ? <i className="bx bxs-lock-open"></i>
                            : <i className="bx bxs-lock"></i>
                          }
                        </div>
                        </InputGroup>
                    </div>
                  </Col>
                  {
                    msgErro != ''
                    &&
                    <Col lg={12}>
                      <small style={{color: '#c7413a'}}>{msgErro}</small>
                    </Col>
                  }
                  <div className="mt-3 d-grid">
                    <button
                      className="btn btn-primary btn-block"
                      type="submit"
                      onClick={() => handleAlteraSenha()}
                    >
                      Log In
                    </button>
                  </div>
                </Row>
              </div>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
