module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
    SECRET: "",
  },
  api: {
    URL_LOCAL: "http://95.216.190.87:3005/",
    //URL_LOCAL: "http://localhost:3005/",
  },
}
